<template>
  <div class="background-order">
    <b-container>
      <b-breadcrumb class="title-page">
        <b-breadcrumb-item href="#" class="size-breadcrumb text-info">{{
          $t('Myorder.header')
        }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-row>
        <div class="w-100 mb-5" id="bk-my-order">
          <b-card no-body>
            <b-tabs pills card v-model="tabIndex" lazy>
              <b-tab :title="$t('Myorder.all')">
                <item-order :status="undefined" :fields="fields"></item-order>
              </b-tab>
              <b-tab :title="$t('Myorder.paid_tab')">
                <item-order :status="0" :fields="fields"></item-order>
              </b-tab>
              <b-tab :title="$t('Myorder.partial')">
                <item-order :status="-1" :fields="fields"></item-order>
              </b-tab>
              <b-tab :title="$t('Myorder.unpaid')">
                <item-order :status="1" :fields="fields"></item-order>
              </b-tab>
              <b-tab :title="$t('Myorder.cancel_tab')">
                <item-order :status="2" :fields="fields"></item-order>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {
    ItemOrder: () => import('./item_order'),
  },
  data() {
    return {
      fields: [
        {
          key: 'order_information',
          label: this.$t('Myorder.order_information'),
          class: 'order_information',
        },
        {
          key: 'order_type',
          label: this.$t('Myorder.type'),
        },
        {
          key: 'tour_date',
          label: this.$t('Myorder.tour_date'),
        },
        {
          key: 'payment_amount',
          label: this.$t('Myorder.payment_amount'),
          class: 'payment_amount',
        },
        {
          key: 'order_status',
          label: this.$t('Myorder.order_status'),
        },
        {
          key: 'operation',
          label: this.$t('Myorder.operation'),
        },
      ],
      tabIndex: 0,
    };
  },
};
</script>

<style type="text/css">
.card-header a {
  font-size: 1.1875rem;
  color: #333333;
}

.tab-content {
  min-height: 350px;
}

.background-order {
  background-color: #f3f6f8;
}

.size-breadcrumb {
  font-weight: 500;
  font-size: 1.6625rem;
}

.title-page {
  height: 70px;
  padding-top: 32px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f6a604;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #ffffff !important;
}

.nav-item {
  padding: 0.5rem !important;
}

.span--order-number {
  color: #1997f8;
}

.card-header {
  border-bottom: 1px solid rgba(122, 123, 151, 0.3);
}

.div--text-info {
  opacity: 0.6;
}
.payment_amount {
  text-align: left;
}
th.order_information {
  width: 35%;
}
</style>
